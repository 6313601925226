<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mb-2 pb-50 mt-2">
      {{ $t('pages.history.name') }}
    </h1>

    <div class="mt-3 mb-3">
      <template v-if="error">
        <div
          class="mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.history.day.loading-error') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="$router.back()"
            >
              {{ $t('therms.go-back') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mb-1 pb-2 d-flex align-items-center justify-content-between position-relative">
          <div class="d-flex align-items-center">
            <touch-icon-button
              :disabled="loader"
              @action="chooseDate"
            >
              <i class="fa-solid fa-calendar-days" />
            </touch-icon-button>

            <div class="ml-1 mb-0 ">
              <span class="lead font-weight-bold">{{ formatDate }}</span>
              <small class="d-block text-secondary">
                {{ !loader && formatPulledAtTime !== null ? $t('therms.archive') : $t('therms.loading') }}
              </small>
            </div>

            <input
              ref="date"
              v-model.lazy="date"
              type="date"
              class="inputDate"
            >
          </div>

          <touch-icon-button
            v-if="loader || (harvestCrop !== null && Object.values(harvestCrop).length>=1)"
            :disabled="loader"
            @action="displayFilters = !displayFilters"
          >
            <i
              v-if="!displayFilters"
              class="fa-solid fa-filter"
            />
            <i
              v-else
              class="fa-solid fa-filter-slash"
            />
          </touch-icon-button>
        </div>

        <template
          v-if="loader || (harvestCrop !== null && Object.values(harvestCrop).length>=1)"
        >

          <b-card
            class="mb-1"
          >
            <b-row>
              <b-col
                class="d-flex justify-content-between align-items-center"
              >
                <b-card-text class="mr-75 mb-0">
                  {{ $t('therms.hide-inactive-harvester') }}
                </b-card-text>
                <b-form-checkbox
                  v-model="onlyStaffMemberActive"
                  :disabled="displayFilters && activeStaffMemberSearch !== null"
                  class="custom-control-secondary mr-0"
                  style="position: relative; right: -6px"
                  name="check-button"
                  switch
                  inline
                />
              </b-col>
            </b-row>
          </b-card>

          <transition name="scale-in">
            <div v-if="displayFilters">
              <b-card class="mb-1">
                <b-row>
                  <b-col>
                    <b-card-text class="mb-50">
                      {{ $t('therms.filter-list-by') }} :
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex">
                    <b-form-input
                      v-model="searchStaffMember"
                      class="mr-75"
                      style="border-radius: 0.6rem"
                      :placeholder="$t('therms.number-lastname-firstname')"
                    />
                    <touch-icon-button
                      v-if="activeStaffMemberSearch === null"
                      :disabled="searchStaffMember === null || searchStaffMember.trim().length === 0"
                      style="min-width: 39px"
                      type="submit"
                      @action="searchStaffMemberHandler"
                    >
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        class="cursor-pointer"
                      />
                    </touch-icon-button>
                    <touch-icon-button
                      v-else
                      style="min-width: 39px"
                      color="danger"
                      @action="clearStaffMemberSearch"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="cursor-pointer"
                      />
                    </touch-icon-button>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="mb-1">
                <b-row>
                  <b-col>
                    <b-card-text class="mb-50">
                      {{ $t('therms.filter-list-by-time') }} :
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex">
                    <b-form-input
                      v-model="from_time"
                      :disabled="loader"
                      class="mr-75 d-flex"
                      type="time"
                      style="border-radius: 0.6rem"
                      :placeholder="$t('therms.start-time')"
                    />
                    <b-form-input
                      v-model="to_time"
                      :disabled="loader"
                      class="mr-75 d-flex"
                      type="time"
                      style="border-radius: 0.6rem"
                      :placeholder="$t('therms.end-time')"
                    />
                    <touch-icon-button
                      v-if="activeTimeSearch === null || (from_time !== activeTimeSearch.from_time || to_time !== activeTimeSearch.to_time)"
                      :disabled="loader || from_time === null || to_time === null"
                      style="min-width: 39px"
                      type="submit"
                      @action="searchTimeHandler"
                    >
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        class="cursor-pointer"
                      />
                    </touch-icon-button>
                    <touch-icon-button
                      v-else
                      :disabled="loader"
                      style="min-width: 39px"
                      color="danger"
                      type="button"
                      @action="clearTimeSearch"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="cursor-pointer"
                      />
                    </touch-icon-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="mt-1 d-flex"
                    style="gap: 8px"
                  >
                    <touch-button
                      :outline="!morningTimeIsSelected"
                      :disabled="loader"
                      size="xs"
                      type="submit"
                      @action="selectMorningTime"
                    >
                      {{ $t('therms.morning') }}
                    </touch-button>
                    <touch-button
                      :outline="!afternoonTimeIsSelected"
                      :disabled="loader"
                      size="xs"
                      type="submit"
                      @action="selectAfternoonTime"
                    >
                      {{ $t('therms.afternoon') }}
                    </touch-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </transition>

          <template v-if="loader">
            <div class="d-flex align-items-center justify-content-center my-5 py-2">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
              />
            </div>
          </template>
          <template v-else>
            <b-card
              class="mb-1"
            >
              <b-row>
                <b-col>
                  <b-card-text class="mb-50">
                    {{ $t('therms.sort-list-by') }} :
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <touch-button-group class="w-100 d-flex">
                    <touch-button
                      outline
                      class="flex-fill"
                      :class="{'active' : sort === sortPerWeight}"
                      ingroup
                      size="sm"
                      style="width: 33%"
                      @action="sort = sortPerWeight"
                    >
                      <span>{{
                        capitalizeFirstLetter($t('units.kilogram')
                          .toString())
                      }}</span>
                    </touch-button>
                    <touch-button
                      outline
                      class="flex-fill"
                      :class="{'active' : sort === sortPerQuantity}"
                      ingroup
                      size="sm"
                      style="width: 33%"
                      @action="sort = sortPerQuantity"
                    >
                      <span>{{
                        capitalizeFirstLetter($t('units.quantity')
                          .toString())
                      }}</span>
                    </touch-button>
                    <touch-button
                      outline
                      class="flex-fill"
                      :class="{'active' : sort === sortPerCode}"
                      ingroup
                      size="sm"
                      style="width: 33%"
                      @action="sort = sortPerCode"
                    >
                      <span>{{
                        capitalizeFirstLetter($t('therms.number')
                          .toString())
                      }}</span>
                    </touch-button>
                  </touch-button-group>
                </b-col>
              </b-row>
            </b-card>

            <div class="mt-3" />

            <b-card
              class="mb-1 "
            >
              <b-row>
                <b-col>
                  <touch-button-group class="w-100 d-flex">
                    <touch-button
                      class="flex-fill"
                      :outline="mode !== modeSimplified"
                      ingroup
                      thinkiness
                      size="sm"
                      @action="mode = modeSimplified"
                    >
                      {{ $t('therms.simplified-view') }}
                    </touch-button>
                    <touch-button
                      class="flex-fill"
                      :outline="mode !== modeGraphical"
                      ingroup
                      thinkiness
                      size="sm"
                      @action="mode = modeGraphical"
                    >
                      {{ $t('therms.graphical-view') }}
                    </touch-button>
                  </touch-button-group>
                </b-col>
              </b-row>
            </b-card>

            <template v-if="mode === modeSimplified">
              <PickingCardResume
                v-for="crop in harvestCropStaffMember"
                :id="crop.id"
                :key="crop.id"
                class="animate__animated"
                :class="{'animate__fadeInUp' : starting}"
                :emoji="crop.emoji"
                :name="crop.name"
                :total-weight="crop.total_weight"
                :quantity="crop.quantity"
                :items="crop.harvest"
                :variant="'primary'"
                :filter-active="onlyStaffMemberActive && activeStaffMemberSearch === null"
                :sort-by="sort"
              />
            </template>

            <template v-if="mode===modeGraphical">
              <PickingBarChartCardResume
                v-for="crop in harvestCropStaffMember"
                :id="crop.id"
                :key="crop.id"
                class="animate__animated"
                :class="{'animate__fadeInUp' : starting}"
                :emoji="crop.emoji"
                :name="crop.name"
                :total-weight="crop.total_weight"
                :quantity="crop.quantity"
                :items="crop.harvest"
                :variant="'primary'"
                :color="chartColors.primaryColorShade"
                :filter-active="onlyStaffMemberActive && activeStaffMemberSearch === null"
                :sort-by="sort"
                :unit="$t('units.kg').toString()"
              />
            </template>
          </template>
        </template>
        <template v-else>
          <div
            class="mb-5 text-muted text-center d-flex flex-column px-3"
          >
            <h1 class="mt-3 mb-2">
              <i class="fa-solid fa-empty-set" />
            </h1>
            {{ $t('pages.history.day.no-data') }}

            <div>
              <TouchButton
                size="xs"
                class="mt-2"
                @action="$router.back()"
              >
                {{ $t('therms.go-back') }}
              </TouchButton>
            </div>
          </div>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import TouchIconButton from '@core/components/touch-button/TouchIconButton.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'
import {
  BCard, BCardText, BCol, BFormCheckbox, BFormInput, BRow, BSpinner,
} from 'bootstrap-vue'
import { Toast } from '@capacitor/toast'
import PickingCardResume from '@/views/components/PickingCardResume.vue'
import PickingBarChartCardResume from '@/views/components/PickingBarChartCardResume.vue'
import chartColors from '@core/mixins/charts/colors'
// eslint-disable-next-line import/named
import { sortPerCode, sortPerQuantity, sortPerWeight } from '@/declations/PickingResumeSort'
// eslint-disable-next-line import/named
import { modeGraphical, modeSimplified } from '@/declations/PickingDisplayMode'

// eslint-disable-next-line func-names
const handleDateFocus = function () {
  if (this.getAttribute('type') === 'date') {
    this.showPicker()
  }
}

const morningTime = ['00:00', '11:59']
const afternoonTime = ['12:00', '23:59']

export default {
  components: {
    NavButtonBar,
    NavButton,
    TouchIconButton,
    TouchButton,
    TouchButtonGroup,
    BSpinner,
    PickingCardResume,
    PickingBarChartCardResume,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,
      starting: true,
      displayFilters: false,
      onlyStaffMemberActive: true,
      sort: sortPerWeight,
      mode: modeSimplified,

      // data
      date: null,
      harvestCrop: null,
      searchStaffMember: null,
      activeStaffMemberSearch: null,
      from_time: null,
      to_time: null,
      activeTimeSearch: null,
    }
  },
  computed: {
    /**
     * @returns {string}
     */
    formatDate() {
      return this
        .$moment(this.date)
        .format('DD/MM/YYYY')
    },
    /**
     * @returns {string}
     */
    sortPerQuantity() {
      return sortPerQuantity
    },
    /**
     * @returns {string}
     */
    sortPerWeight() {
      return sortPerWeight
    },
    /**
     * @returns {string}
     */
    sortPerCode() {
      return sortPerCode
    },
    /**
     * @returns {string}
     */
    modeSimplified() {
      return modeSimplified
    },
    /**
     * @returns {string}
     */
    modeGraphical() {
      return modeGraphical
    },
    /**
     * @returns {string|null}
     */
    formatPulledAtTime() {
      if (this.harvestCrop === null || this.harvestCrop?.pulled_at === undefined) return null

      return this
        .$moment(this.harvestCrop.pulled_at)
        .format('HH:mm')
    },
    /**
     * @returns {{infoColorShade: string, lineChartPrimary: string, area: {series3: string, series2: string, series1: string}, blueColor: string, grid_line_color: string, primaryColorShade: string, warningLightColor: string, greyColor: string, blueLightColor: string, tooltipShadow: string, column: {bg: string, series2: string, series1: string}, labelColor: string, warningColorShade: string, greyLightColor: string, success: {shade_200: string, shade_100: string}, yellowColor: string, donut: {series4: string, series3: string, series5: string, series2: string, series1: string}, successColorShade: string, lineChartDanger: string}}
     */
    chartColors() {
      return chartColors
    },
    /**
     * @returns {*}
     */
    harvestCropStaffMember() {
      return JSON.parse(JSON.stringify(this.harvestCrop.cropHarvest))
        .map(crop => {
          // eslint-disable-next-line no-param-reassign
          crop.harvest = crop.harvest
            .map(staffMember => {
              // eslint-disable-next-line no-param-reassign
              staffMember.name = `${staffMember.firstname.trim()} ${staffMember.lastname.trim()}`.trim()
              return staffMember
            })

          if (this.activeStaffMemberSearch !== null) {
            // eslint-disable-next-line no-param-reassign
            crop.harvest = crop.harvest.filter(st => st.name.toLowerCase()
              .indexOf(this.activeStaffMemberSearch.toLowerCase()) !== -1
                  || (`${st.code}`).toLowerCase()
                    .indexOf(this.activeStaffMemberSearch.toLowerCase()) !== -1)
          }

          return crop
        })
    },
    morningTimeIsSelected() {
      return this.from_time === morningTime[0] && this.to_time === morningTime[1]
    },
    afternoonTimeIsSelected() {
      return this.from_time === afternoonTime[0] && this.to_time === afternoonTime[1]
    },
  },
  watch: {
    async date() {
      if (this
        .$moment(this.date)
        .isAfter(this.$moment())) {
        this.date = this.$moment()
          .toDate()
      }
    },
    /**
     * @returns {Promise<void>}
     */
    async formatDate() {
      await this.loadDataOfDate()
    },
    displayFilters(val) {
      if (!val) {
        this.clearStaffMemberSearch()
        this.clearTimeSearch()
      }
    },
  },
  mounted() {
    this.date = this.$moment(this.$router.currentRoute.params.date)
      .locale('en')
      .format('YYYY-MM-DD')

    this.$nextTick(() => {
      setTimeout(() => {
        this.starting = false
      }, 800)
    })

    // eslint-disable-next-line func-names
    this.$refs.date.addEventListener('focus', handleDateFocus)
  },
  beforeDestroy() {
    this.$refs.date.removeEventListener('focus', handleDateFocus)
  },
  methods: {
    /**
     * @param {string} string
     * @return {string}
     */
    capitalizeFirstLetter(string) {
      return window.capitalizeFirstLetter(string)
    },
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
    chooseDate() {
      this.$refs.date.blur()
      setTimeout(() => {
        this.$refs.date.click()
        this.$refs.date.focus()
      }, 200)
    },
    searchStaffMemberHandler() {
      if (this.searchStaffMember !== null && this.searchStaffMember.trim().length === 0) {
        this.clearStaffMemberSearch()
        return
      }

      this.activeStaffMemberSearch = this.searchStaffMember
    },
    clearStaffMemberSearch() {
      this.activeStaffMemberSearch = null
      this.searchStaffMember = null
    },
    searchTimeHandler() {
      if (this.from_time === null || this.to_time === null) {
        return this.clearTimeSearch()
      }

      this.activeTimeSearch = {
        from_time: this.from_time,
        to_time: this.to_time,
      }

      this.loadDataOfDate(false)
    },
    clearTimeSearch() {
      this.activeTimeSearch = null
      this.from_time = null
      this.to_time = null

      this.loadDataOfDate()
    },
    selectMorningTime() {
      this.from_time = morningTime[0]
      this.to_time = morningTime[1]

      this.searchTimeHandler()
    },
    selectAfternoonTime() {
      this.from_time = afternoonTime[0]
      this.to_time = afternoonTime[1]
      this.searchTimeHandler()
    },
    async loadDataOfDate(useStore = true) {
      if (this.loader) return

      try {
        const date = this.$moment(this.date)
          .locale('en')
          .format('YYYY-MM-DD')

        this.error = false
        this.loader = true
        this.harvestCrop = null

        await sleep(200)

        if (useStore) {
          if (!await this.$store.dispatch('harvestHistory/has', date)) {
            const result = await this.$store.dispatch('harvestHistory/load', {
              date,
              force: true,
              byPassStorageCaching: true,
            })

            if (result === true || result.cropHarvest.length === 0) {
              await sleep(200)
              Toast.show({
                text: this.$t('pages.history.day.no-data-for-this-date')
                  .toString(),
              })
                .then()

              throw new Error('No data for this date')
            }
            this.harvestCrop = result
          } else {
            await this.$store.dispatch('harvestHistory/load', {
              date,
            })
            this.harvestCrop = await this.$store.dispatch('harvestHistory/getHarvestCrop', { date })
          }
        } else {
          const filters = {
            ...(this.activeTimeSearch === null ? {} : {
              from_time: `${this.activeTimeSearch.from_time}:00`,
              to_time: `${this.activeTimeSearch.to_time}:59`,
            }
            ),
          }

          const result = await this.$store.dispatch('harvestHistory/load', {
            date,
            filters,
            force: true,
            byPassStorageCaching: true,
          })

          if (result === true || result.cropHarvest.length === 0) {
            await sleep(200)
            Toast.show({
              text: this.$t('pages.history.day.no-data-for-this-date')
                .toString(),
            })
              .then()

            throw new Error('No data for this date')
          }
          this.harvestCrop = result
        }

        this.loader = false
      } catch (err) {
        this.loader = false
        this.error = true

        throw err
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

table tr td:nth-child(2) {
  padding-left: 20px;
}

.inputDate {
  position: absolute;
  z-index: -100;
  bottom: 0;
  left: 0;
  opacity: 0;
}

</style>
